var main = function() {

    var pais = null;

    var init = function () {
        let flPais = document.getElementById('fl_pais');
        if(flPais) {
            flPais.addEventListener('change', function() {
                pais = this.value.toLowerCase();
                provincias();
            });
        }
    }

    var provincias = function () {
        makeRequest('/provincias/'+pais, 'GET', function (data) {
            let provincias =data.provincias;
            let select = document.getElementById("fl_provincia");
            clearSelect(select);
            Object.keys(provincias).forEach(id => {
                let o = document.createElement("option");
                o.textContent =  provincias[id];
                o.value = parseInt(id)+1;
                select.appendChild(o);
            });
            select.selectedIndex = 0;
        })
    }

    var makeRequest = function (url, method, action) {
        fetch(url, {
            method: method,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        }).then(function(response) { return response.json(); })
        .then(function(data) {
            action(data.response.data)
        }).catch(function(e) {});
    }

    var clearSelect = function (select, disable = false) {
        while (select.options.length > 1) {
            select.remove(1);
        }
        select.disabled = disable;
    }

    return {
        init: init,
    };
}();

document.addEventListener("DOMContentLoaded", function(event) {
    main.init();
});
